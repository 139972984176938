<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('vendor.add.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-form ref="userForm" lazy-validation>
            <v-text-field
              v-model="vendor.name"
              :counter="255"
              :rules="validation.nameRules"
              :label="$t('vendor.add.name')"
              required
            ></v-text-field>

            <v-text-field
              v-model="vendor.adress"
              :counter="255"
              :rules="validation.adress"
              :label="$t('vendor.add.adress')"
              required
            ></v-text-field>


            <v-text-field
              v-model="vendor.email"
              :counter="255"
              :rules="validation.email"
              :label="$t('vendor.add.email')"
              required
            ></v-text-field>


            <v-text-field
              v-model="vendor.telephone"
              :counter="255"
              :rules="validation.telephone"
              :label="$t('vendor.add.phone')"
              required
            ></v-text-field>

            <v-autocomplete
              style="margin-top: 10px"
              v-model="vendor.countries"
              :items="countries"
              dense
              filled
              :label="$t('vendor.add.countries')"
              multiple
              item-value="id"
              item-text="nicename"
            ></v-autocomplete>

            <v-btn
              color="success"
              class="mr-4 align-self-lg-end"
              @click="createVendor()"
            >
            {{ $t('vendor.add.controlls.create') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "CreateVednor",
  data() {
    return {
      error: null,
      isLoading: true,
      validation: {
        name: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 255) || "Name must be less than 255 characters",
        ],
        adress: [
          (v) => !!v || "Adress is required",
          (v) =>
            (v && v.length <= 255) || "Adress must be less than 255 characters",
        ],
        telephone: [
          (v) => !!v || "Telephone is required",
          (v) =>
            (v && v.length <= 255) || "Telephone must be less than 255 characters",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
      vendor: {
        name: "",
        adress: "",
        email: "",
        telephone: "",
        countries: null,
      },
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    countries() {
      if(this.applicationData) {
        return this.applicationData.countries; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch("loadApplicationData")
  },
  methods: {
    createVendor() {
      this.$axios({
        method: "post",
        url: this.$globals.api.vendors.create,
        data: this.vendor,
      })
        .then(() => {
          this.$router.push({ name: "vendor/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
  
};
</script>